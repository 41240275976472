module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K9VR367","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-K9VR367","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"vierfotografen-static-page","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"src/images/fav.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"36345249dc74c54a829aec70a81d171b"},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"develop":{"hardCacheMediaFiles":true},"verbose":true,"debug":{"graphql":{"panicOnError":false,"writeQueriesToDisk":true,"showQueryVarsOnError":true,"onlyReportCriticalErrors":true}},"url":"https://cms.vierfotografen.de/graphql","auth":{},"perPage":100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
